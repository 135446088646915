.logo-project {
}
.square-image .ant-card-cover .ant-avatar,
.square-image .cover-type {
  border-radius: 0px;
  width: 100% !important;
  min-height: 200px;
}
.square-image .ant-card-body {
  background: #ccc;
  padding: 0px !important;
}
.square-image .ant-btn.btn-file-upload {
  width: 100% !important;
  border-radius: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
  color: #fff !important;
  background: rgba(8, 8, 8, 0.856) !important;
}
.square-image .card-image {
  max-height: 400px !important;
}

.square-image .cover-type {
  border-radius: 0px !important;
  /* border: 0px !important; */
}
.square-image .logo-project {
  /* background: red!important; */
  /*  max-width: 32% !important; */
  /* margin: 0px auto; */
}
.logo-project {
  max-width: 32% !important;
}
.square-image .file_name {
  display: none !important;
}
.square-image .cover-type > div {
  /*  background-size: contain !important; */
}
.image-principal {
  height: 600px !important;
  /* background: red !important; */
}
.image-principal .ant-card-cover .cover-type {
  height: 100% !important;
}
.image-principal .ant-card-cover .cover-type > div {
  background-size: cover !important;
}
.image-principal .ant-card-cover .ant-avatar {
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-principal .ant-card-cover {
  height: calc(100% - 40px);
}
/* ant-avatar avatar-icon ant-avatar-circle ant-avatar-icon */
.form-card .item-form {
  padding: 0px !important;
}
.projects-container .crud-container {
  padding: 10px !important;
  margin: 10px 0px !important;
  box-shadow: none !important;
}
.card-form {
  width: 100%;
  background: #fafafa;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 1px 1px 4px 4px #cccccc2b;
}


/* url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);  */


 .list-checks {
   flex-direction: column !important;
 }

/* @media(max-width: 990px){
  .col-filters{
    max-width: 0 !important;
    padding: 0 !important;
    position: static !important;
  }
} */

/* ------------------------------------------------------------------------ */
@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-150%);
  }
}

.exit_form  {
  animation-name: moveLeft;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}


@keyframes moveRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
} 

.open_form {
  animation-name: moveRight;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

/* ------------------------------------------------------------------------ */


/* ----------------------- STYLE ACORDION --------------------------------- */
.accordion {
  --bs-accordion-color: #585E673F !important;
  --bs-accordion-bg:  #585E673F !important;
  --bs-accordion-transition: none !important;
  --bs-accordion-border-color: #585E673F !important;
  --bs-accordion-border-width: 0px !important;
  --bs-accordion-border-radius: 10px !important;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color:#585E673F !important;
  --bs-accordion-btn-bg: #585E673F !important;
  --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e) !important;
  --bs-accordion-btn-icon-width: 13px !important;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e) !important;
  --bs-accordion-btn-focus-border-color: #585E673F !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #000 !important;
  --bs-accordion-active-bg: #585E673F !important;
}

.content_accordion {
  padding: 0 9px !important;
}

.accordion-button{
  padding: 1rem 0.5rem !important;
  color: black !important;
}

.accordion-body{
  background: #f6f6f6 !important;
}

.css-tlfecz-indicatorContainer {
  color: #212529 !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  box-sizing: border-box;
}

/* ----------------------- STYLE ACORDION --------------------------------- */

.add-filters{
    letter-spacing: 0;
    color: #000 !important;
    text-transform: uppercase;
    font-size: 0.8125rem;
    margin-bottom: 10px;
    padding: 0 8px;
}

.accordion-item{
  color: #000 !important; 
  background-color: unset !important;
}

.accordion-item:first-of-type {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.accordion-item:last-of-type {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}