.my-modal .ant-select.ant-select-enabled > * {
  width: 100% !important;
  /* background: red !important; */
}
form .ant-select,
form .ant-cascader-picker {
  width: 100% !important;
}
.my-modal .form-type{
  padding:0px 20px!important;
}
.form-type .ant-btn.btn-file-upload{
  width: auto!important;
  padding: 10px!important;
}