.header-type > * {
  font-family: 'HK Grotesk' !important;
}
.ant-list-split .ant-list-item {
  border-bottom: 0px !important;
  padding: 5px !important;
}
.header-type h3 {
  font-size: 24px !important;
  min-width: 200px;
}
.header-type h3 .gvLdgB svg:not(:root) {
  overflow: hidden;
  vertical-align: middle !important;
}
.header-type i {
  padding: 10px 20px !important;
  vertical-align: middle;
}
/* .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0px !important;
} */
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.item-card-phase-collapse .ant-collapse-header {
  padding-left: 0px !important;
}

.tipos-fases-collapse .ant-collapse-header {
  padding-left: 20px !important;
  font-size: 24px;
  color: #797979 !important;
}
.tipos-fases-collapse .ant-collapse-header svg {
  color: #d500f9 !important;
}
.tipos-fases-collapse .ant-collapse-header span {
  margin-left: 20px !important;
}
.item-card-phase .ant-card-body {
  padding: 0px !important;
}
.form-container-phase form {
  padding: 20px;
}
.header-item-card {
  padding: 10px !important;
  margin: 0px !important;
}
.ant-row-flex.ant-row-flex-start.ant-row-flex-middle.header-item-card {
  color: #797979 !important;
  font-size: 16px !important;
  font-family: 'HK Grotesk' !important;
}
.tipos-fases-collapse {
}
.btn-gray:hover,
.btn-gray:focus,
.btn-gray {
  background: rgb(204, 204, 204) !important;
  color: rgb(130, 130, 130) !important;
  font-size: 18px !important;
  font-weight: bold !important;
  border: 0px !important;
  opacity: 0.5;
  padding: 5px;
}
/* .btn-gray i,
.btn-gray svg {
  vertical-align: middle;
} */
