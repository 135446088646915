

.imagen_card {
    width: 204px !important;
    height: 158px !important
}

.CardNews_container {
    margin-bottom: 1rem;
    cursor: pointer !important;
}

.CardNews{

    display: flex !important;
    flex-direction: row !important;
}


.content__categoria{
    background-color: #69A2D7;
    padding: 0.4rem;
    width: 98px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: #fff; 
}

.content_title{
    margin-top: 1rem;
    /* font-family: 'Lato'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
}

.title {
    margin-bottom: 0;
}
.title_autor{ 
    margin-top: 0.3rem;
    font-weight: 600;
}
.date_logo{
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.date {
    text-transform: capitalize;
    font-size: 0.7rem;
    
}

.Column {
    padding: 0 1rem;
    
}

@media(max-width: 579px){
    .CardNews {
        padding: 1rem !important;
        display: flex !important;
        flex-direction: column !important;
        /* flex-direction: column !important; */
    }

    .content__categoria{
       margin-top: 1rem;
    }

    .imagen_card{
        height: 15rem !important;
        width: 100% !important;
    }

    .Column {
        padding: 0;
    }
}