

.ant-select-selection > * {
  font-family: 'HK Grotesk' !important;
}
.ant-select-selection {
  background: #f8f8f8 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  min-height: 40px !important;
  border: 0px !important;
}

.ant-select-selection__rendered {
  line-height: 40px !important;
}
.ant-select-selection.ant-select-selection--single:focus {
  border: 0px !important;
  box-shadow: none !important;
}
.ant-select-select-focused > * {
  border: 0px !important;
  outline: none !important;
  box-shadow: none !important;
}
.ant-select-arrow {
  top: calc(50% - 5px) !important;
}
/* .ant-select-dropdown-hidden {
  display: block !important;
} */
.ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  font-family: 'HK Grotesk' !important;
  min-height: 40px !important;
  line-height: 30px !important;
}
.ant-select-dropdown-menu .ant-select-dropdown-menu-item:hover {
  background-color: #ececec !important;
}
.ant-select-dropdown-menu-item-selected {
  background-color: #dcdcdc !important;
}
