.container_blogs{
   margin-top: 60px!important;
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 33px;
    padding-right: 1rem;
}

.footer_card {
    padding-left: 8rem !important;
    padding-right: 2rem !important;
}

.categoria_blog{
    width: 98px !important;
    height: 27px !important;
    background: #69A2D7;
    border-radius: 10px;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
}

.text_categori{
    margin: 0;
    display: flex;
    justify-content: space-between;
}

.content_categori{
    margin: 0.5rem 0 0.5rem 0;
    /* padding: 0.3rem !important; */
    display: flex !important;
    justify-content: space-between !important;
}

.date_logo{
    width: 100%;
    display: flex;
    justify-content: space-between;

}

@media (max-width: 596px) {
  

    .column_news{
        margin-right: 0 !important;
        margin-left: 0 !important
    }
    .title{
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 106%;
    }
}

@media(max-width:900px) {
    .container_blogs {
        margin: 3rem 0 !important;
        padding: 1rem !important;
    }
}