.action-container {
  margin: 0px auto !important;
}
.crud-container {
  background: #fff !important;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 4px 4px #cccccc2b;
  margin: 10px;
}
.crud-list {
  padding: 10px 20px;
  /* border: 1px solid #ccc;
  border-radius: 10px; */
}
.crud-container .ant-list-header,
.crud-container .ant-list-footer {
  padding-bottom: 0px !important;
}
.form-simple-field .ant-col .ant-form-item-control-wrapper {
  width: 100% !important;
}
.crud-list {
  padding: 0px !important;
}
.form-simple-field .card-container {
  padding: 0px !important;
  margin: 0px !important;
}
.form-simple-field {
  border: 1px solid #ccc;
  padding: 0px 8px;
  border-radius: 8px;
}
.crud-container  .ant-slider {
  width: auto !important;
}
