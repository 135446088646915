@import "./sources/css/styles.css";
@import "./styles/theme.css";
.custom-field {
  border: none;
  box-shadow: none;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 5px;
  /*  min-width: 200px; */
  max-width: 100%;
  min-height: 40px;
}
.btn-gray {
  background: rgb(204, 204, 204) !important;
  color: rgb(130, 130, 130) !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

.jjyGks {
  object-fit: contain !important;
  padding: 5px !important;
}
.p-h1 {
  font-size: 20px !important;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
/* Label range area */
.gvLdgB .mb-2 {
  text-align: center !important;
}
.card-form .card-container {
  margin: 0px !important;
}
.card-form .login-form-button {
  background: rgba(8, 8, 8, 0.856) !important;
  border: 1px solid rgba(8, 8, 8, 0.856) !important;
}
.card-form .ant-form-item {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  color: #fff;
  background: transparent linear-gradient(91deg, #0092d0 0%, #734dff 100%) 0% 0%
    no-repeat padding-box;
  border-color: #734dff;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0px !important;
}
.group-fields .form-group {
  /* background: red!important; */
  width: 50%;
  padding: 28px 0px 0px 0px !important;
}
.group-fields-row .sc-hgHYgh.iYpsLF {
  margin-top: -10px !important;
}
.pl-2.text-muted.text-capitalize {
  text-align: left !important;
}
.bGxmmZ.navbar {
  z-index: 80 !important;
  background: white !important;
}
.my-marker {
  cursor: pointer;
}
.ant-tooltip-inner {
  color: #292b2c !important;
  background-color: rgba(255, 255, 255, 0.75) !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
}
.ant-tooltip-arrow::before {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

@media(max-width:768px){
  .gvLdgB .h-100 {
      height: auto !important;
  }
  .d-flex.sc-cIShpX.dunGWh{
    margin: 10px 0px!important;
    max-width: 100%!important;
  }

  .sc-fYxtnH.sc-eerKOB.dJHQMw.container{
    padding-top: 8.375rem!important;
  }
  .sc-fYxtnH.sc-bMvGRv.jRKQGk.container {
      padding: 16px;
  }
  /* Gallery */
  .gallery-wrapper{
    display: flex!important;
  }
  .gallery-wrapper > div:nth-child(2){
    display: none!important;
  }
  .gallery-wrapper > div:nth-child(1) {
    display: block!important;
    flex: 1!important;
  }
}

@media(max-width:414px){

   .gallery-wrapper{
    display: flex!important;
  }
  .gallery-wrapper > div:nth-child(2){
    display: none!important;
  }
  .gallery-wrapper > div:nth-child(1) {
    display: block!important;
    flex: 1!important;
  }

  /* .projects-search{
      background: #292b2cbd!important;
      height: 300px!important;
      padding: 8px 5px!important;
      box-shadow: 10px 13px 13px #0b0b0b00!important;
      border: 1px solid #292b2c59!important;
  } */
  /* .projects-search .input-group-btn{
    min-height: 40px!important;
  } */
   .input-group-btn .btn.btn-success{
    padding: 10px!important;
    margin: 10px 0px!important;
    border-radius: 8px!important;
  }
}

.bGxmmZ.navbar-light .navbar-nav .nav-link.disabled {
  color: #d4d4d4!important;
}
/* .bGxmmZ.navbar-light .navbar-nav .nav-link.disabled i{

} */
.ant-affix{
  z-index: 99999!important;
}
img.pswp__img {
  object-fit: contain!important;
}
.list-checks{
  display: flex!important;
  justify-content: start!important;
  flex-wrap: wrap!important;
}
.list-checks label{
  margin: 0px 4px;
}
.list-checks label span{
  margin-left: 0px!important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(226, 226, 226);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(226, 226, 226);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(226, 226, 226);
}
.ant-affix {
  z-index: 99999!important;
  background: #FFF!important;
}
.whatsapp-icon{
  color:#1ad03f!important;
  font-size: 30px!important;
}



.ais-InstantSearch__root {
  width: 100% !important;
}

.content-context {
  margin-top: 77px !important;
}

.content-project-and-title{
  display: flex;
  flex-direction: column;
}

.input-group{
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.wrapper-context {
  padding: 0 0.5rem !important;
}


.projects-search {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
}

@media(max-width: 900px){
  .content-context {
    margin-top: 66px !important;
  }

 
}

@media(max-width: 1000px){
  .content-context {
    /* margin-top: 66px !important; */
  }

  .projects-search{
    width: 90%;
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  }


}

@media (max-width: 768px){

}