@font-face {
  font-family: 'League Spartan';
  src: url('/public/fonts/League_Spartan/static/LeagueSpartan-Medium.ttf'),
    url('/public/fonts/League_Spartan/static/LeagueSpartan-Regular.ttf');
}

/* * {
  font-family:'League Spartan', sans-serif
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000 !important;
}

.color-slider,
.content-slider {
  background: #fff !important;
  background-color: #fff !important;
}

.create_by_and_datetime {
  padding: 0 !important;
  height: 4rem !important;
  margin-left: 0 !important;
  display: flex;
  justify-content: space-between;
}

.logo_autor {
  background-color: #f0f0f0;
  border-radius: 36px;
  height: 100%;
  width: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-autor_center {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 1rem !important;
}

.date_publication {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

/* Boton de barra de filtros */
.btn-success {
  background: none !important;
  background-color: #65a92e !important;
}

.position-project {}

.center_clear_boton_filter {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
}

.col-md-6 {
  margin-bottom: 1rem !important;
}

.text-title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 185%;
  /* or 59px */
  text-align: center;
  text-transform: uppercase;
}


.text-uppercase {
  font-size: 32px !important;
  color: #1f1f1f !important;
  font-weight: 700 !important;
  text-align: center;
  margin-bottom: 1.5rem;
}

.project-head-line {
  margin: 0 !important;
}

/* Project headline */
.center-item-headline {
  gap: 0.5rem;
  justify-content: start;
  align-items: center;
}

.mb-5 {
  color: #000 !important;
  font-weight: 600 !important;
  margin-bottom: 2rem !important;
}

.navbar {
  padding: 0.81rem 1rem !important;
  /* height: 105px; */
  /* background-color: #D9D9D9 !important; */
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.16) !important;
}

.btn-primary {
  border-radius: 10px !important;
  background: #00438d !important;
  border-color: #00438d !important;
  cursor: pointer;
}

.navbar-toggler {
  /* background-color: aqua !important; */
  border: none !important;
}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none !important;
}

.navbar-toggler-icon {
  color: #00438d !important;
  width: 30px !important;
  height: 30px !important;
}

.nav-styles {
  padding: 0 5rem 0 5rem !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

/* --------------------------NAVBAR------------------ */
.me-auto {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 40px;

}

@media(max-width: 1200px) {
  .me-auto {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: start !important;
    gap: 16px;

  }
}

/* --------------------------NAVBAR------------------ */

.card {
  /* margin: 0 1rem !important; */
}

.slick-prev {
  display: none !important;
}

.slick-arrow {
  /* display: hidden !important; */
}

.slick-next {
  background-image: url(../src/components/home/more.svg) !important;
  background-size: cover !important;
  background-position: center !important;
}

.slick-next:before {
  content: ' ';
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: -10%;
  right: 1.9%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

/* Manejador de busqueda */


@media (max-width: 1000px) {

  .input-group {
    /* height: 30rem !important; */
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    gap: 0.3rem !important;

  }

  .input-group-btn {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem !important
  }
}

@media(max-width: 999px) {

  .diLkVY.input-group .form-control:not(:first-child),
  .diLkVY .input-group-addon:not(:first-child),
  .diLkVY .input-group-btn:not(:first-child)>.btn,
  .diLkVY .input-group-btn:not(:first-child)>.btn-group>.btn,
  .diLkVY .input-group-btn:not(:first-child)>.dropdown-toggle,
  .diLkVY .input-group-btn:not(:last-child)>.btn:not(:first-child),
  .diLkVY .input-group-btn:not(:last-child)>.btn-group:not(:first-child)>.btn {
    border-bottom-left-radius: 5px !important;
    border-top-left-radius: 5px !important;
  }
}

@media (max-width: 933px) {

  .input-group {
    /* height: 21rem !important; */
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    gap: 0.3rem !important;


  }

  .input-group-btn {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem !important
  }

}

@media (max-width: 900px) {
  .navbar {
    padding: 0.4rem 0.5rem !important;
    /* height: 85px !important; */
    /* background-color: #D9D9D9 !important; */
    box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.16) !important;
  }

  .anchor-navbar {
    width: 100%;
    padding: 0.5rem 1rem;
  }

  .form-search {
    position: relative !important;
    top: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    margin-top: 1rem !important;
  }

  .nav-styles {
    display: flex !important;
    /* justify-content: left !important; */
    align-items: center !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    /* justify-content: space-between !important;
        align-items: center !important; */
  }

  .me-auto {
    width: 100% !important;
    display: flex !important;
    justify-content: left !important;
    align-items: left !important;
    gap: 5px;
  }

  .order-data-form {
    order: 1 !important;
  }

  .input-group {
    flex-direction: column !important;
  }

  .input-group-btn {
    /* border-radius: 10px !important; */
  }
}

@media (max-width: 769px) {


  .input-group-btn {
    border-radius: 10px !important;
  }
}


.input-group {
  min-height: 100% !important;

}


@media(min-width: 1200px) {
  .container {
    /* padding-left: 0 !important;
    padding-right: 0 !important; */
  }
}

@media (max-width: 576px) {
  .position-project {
    position: relative !important;
    top: -4rem !important
  }


  .input-group {
    display: flex !important;
    flex-direction: column !important;

    gap: 0.3rem !important;
    flex-wrap: nowrap !important;
  }

  .search-project {
    height: max-content !important;
    padding: 0.5rem 2.5rem !important;
    border-radius: 10px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .input-group-btn {
    height: max-content !important;
    width: 100% !important;
  }


  .form-search {
    margin: 0 !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .form-search-open {
    top: 4rem !important;
    padding: 1rem !important;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .text-title {
    margin: 0;
  }

  a[disabled] {
    width: 100% !important;
  }

  .wrapper-context {
    padding: 1rem !important;
  }

  .input-group-btn {
    border-radius: 10px !important;
  }
}

@media (max-width: 414px) {
  .input-group-btn .btn.btn-success {
    border-radius: 5px !important;
  }
}

.row-title-home{
  margin-top: 4rem;
  margin-bottom: 1rem;
  /* margin-bottom: 2rem; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  text-align: center;
}

.row-title-home-text {
  font-size: 2.6vw; 
}

@media (max-width:564px) {
  .row-title-home-text {
    font-size: 30px; 
  }
}
