/* .ant-advanced-search-form {
    padding: 10px;
}
  
.ant-advanced-search-form .ant-form-item {
    display: flex;
} */

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.ant-advanced-search-form .ant-form-item {
  margin-bottom: 0px !important;
}
.footer-advanced-form {
  margin: 5px;
  padding: 0px;
}
.item-inline .ant-form-item {
  display: flex;
  justify-content: start;
  align-items: center;
}
.item-inline .ant-form-item-label {
  padding: 0px 8px 0px 0px!important;
}
@media(max-width:475px){
  .item-inline .ant-form-item {
    display: block;
  }
  .item-inline .ant-form-item-label {
    padding: 4px 0px!important;
    margin: 4px 0px;
  }
}
