:root{
    --primary-color:#0092d0;
    --color-gray:#9c9c9c;
    --color-white:#fff;
    --color-gray-1:rgba(215, 215, 215, 0.87);
    --color-gray-2:rgba(69, 49, 49, 0.4);
    --color-gray-ligth:#3E4545;
    --color-gray-dark:rgba(69, 90, 100, 0.3);
    --color-light:rgb(255, 255, 255);
    --color-dark:rgba(0,0,0,.65);
    --sidebar-bg:var(--color-light);

    --color-1:#0079bf;
    --color-2:#e4f3da;
    --color-3:#89609e;
    --color-4:#fdd;
    --color-5:#519839;
    --color-6:#cd5a91;
    --color-7:#d29034;
    --color-8:#4bbf6b;
    --color-9:#00aecc;
    --color-10:#838c91;
    
}