.my-modal > * {
  font-family: 'HK Grotesk' !important;
}
.my-modal {
  min-width: 600px !important;
}
/* .ant-modal-body {
  padding: 0px !important;
} */
/* .ant-modal-body > div{
  padding: 8px !important;
} */
/* .my-modal .ant-modal-title i {
  font-size: 24px !important;
  color: #8dc63f !important;
  vertical-align: middle !important;
  opacity: 0.6 !important;
  padding: 0px !important;
  margin: 0px !important;
} 
.my-modal .ant-modal-body {
  padding: 8px !important;
}
*/
